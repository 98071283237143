import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import CustomButton from "../../../components/custom-button";
import CheckUserLoggedInContext from "../../../context/check-user-logged-in-context";
import ConnectBankContext from "../../../context/connect-bank-context";
import DataLoadingContext from "../../../context/data-loading-context";
import SignupContext from "../../../context/sign-up-context";
import { useQueryInvalidationWithNotification } from "../../../hooks";

const SignUpSuccess = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const { pendingToCreateScenarios, setAppState } = useContext(
    DataLoadingContext
  );
  const { setCheckUserLoggedIn } = useContext(CheckUserLoggedInContext);
  const { params } = useContext(SignupContext);
  const { connectBankSuccessRef } = useContext(ConnectBankContext);
  const redirect =
    new URLSearchParams(window.location.search).get("redirect") || "/";
  const { firstName: name = "" } = params;
  const handleNextProcess = () => {
    setCheckUserLoggedIn(true);
    invalidateQueries("accountList");
    invalidateQueries("financeHistory");
    if (connectBankSuccessRef.current) {
      setAppState({ isNotConnectingToAnyBank: false });
    }
    if (pendingToCreateScenarios.length > 0) {
      setAppState({
        shouldCreatePendingScenario: true,
      });
    }
    invalidateQueries("myInfo");
    navigate(redirect);
  };
  const handleKeyDown = (e) => {
    if (e.key.code === "13") {
      handleNextProcess();
    }
  };
  useEffect(() => {
    setCheckUserLoggedIn(false);
  }, [setCheckUserLoggedIn]);
  return (
    <div className="body-context-container signup-title">
      <span className="header-background">Success.</span>
      <div>Thanks for Joining {name.split(" ")[0]}</div>
      <div className="signup-property-spacing" />
      <CustomButton
        label={redirect === "/" ? `Go to Dashboard` : "Continue"}
        onClick={handleNextProcess}
        onKeyPress={handleKeyDown}
      />
    </div>
  );
};

export default SignUpSuccess;
